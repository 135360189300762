import React, { Component } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class App extends Component {
  render() {
    return (
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    );
  }
}
