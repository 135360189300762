import React from "react";

export default function Marquee() {
  return (
    <div className="digital-marque-sec">
      <div className="container">
        <div className="digital-marque">
          <div className="marquee">
            <div className="track">
              <div className="content">
                <h1>
                  <span>I always try to put my best service to my client</span>{" "}
                  <i>.</i>{" "}
                  <span>I always try to put my best service to my client</span>{" "}
                  <i>.</i>{" "}
                  <span>I always try to put my best service to my client</span>{" "}
                  <i>.</i>{" "}
                  <span>I always try to put my best service to my client</span>{" "}
                  <i>.</i>{" "}
                  <span>I always try to put my best service to my client</span>{" "}
                  <i>.</i>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
