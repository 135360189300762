import React from "react";

export default function Contact() {
  return (
    <section className="tp-contact-form-area section-padding">
      <div className="container">
        <div className="tp-contact-form-wrap">
          <div className="tp-section-title">
            <h2>Send me a Message</h2>
            <p>
              Your email address will not be published. All fields are required
            </p>

            <div className="submit-area">
              <button type="submit" className="theme-btn">
                Submit now
              </button>
            </div>
          </div>
          {/* <ContactForm /> */}
        </div>
      </div>
      <div className="visible-rotate-text">
        <h1>Contact us</h1>
      </div>
    </section>
  );
}
