import React from "react";
import { Element } from "react-scroll";
import About from "../../components/About/About";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/Navbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Marquee from "../../components/Marque/Marque";
import Footer from "../../components/Footer/Footer";
import Portfolio from "../../components/CallToAction";
import Blog from "../../components/Blog";
import Contact from "../../components/Contact";

export default function HomePage() {
  return (
    <>
      <Navbar />
      <Element name="home">
        <Hero />
      </Element>
      <Element name="about">
        <About />
      </Element>
      <Element name="service">
        <ServiceSection />
      </Element>
      <Element name="project">
        <Portfolio />
      </Element>
      <Element name="blog">
        <Blog />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Marquee />
      <Element name="footer">
        <Footer />
      </Element>
    </>
  );
}
