import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import React from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import Contact from "./Contact";
import "./style.css";

export default function ServiceSingle({
  maxWidth,
  open,
  onClose,
  title,
  dImg,
  sImg1,
  sImg2,
  des1,
  des2,
  des3,
}) {
  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  const CloseButton = styled(IconButton)(({ theme }) => ({
    marginLeft: "auto",
    color: theme.palette.grey[500],
  }));

  const CustomDialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <StyledDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <CloseButton aria-label="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        ) : null}
      </StyledDialogTitle>
    );
  };

  const viewerOptions = {
    defaultYaw: 88.5,
    defaultPitch: 44.2,
    defaultZoomLvl: 1,
    src: sImg1,
    height: "100%",
    width: "100%",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        ></CustomDialogTitle>
        <Grid className="modalBody modal-body">
          <div className="tp-service-single-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="tp-service-single-wrap">
                    <div className="tp-service-single-item">
                      {/* <div className="tp-service-single-main-img">
                        {title === "Virtual Tours" ? (
                          <div className="panorama-container">
                            <ReactPhotoSphereViewer {...viewerOptions} />
                            <div className="overlay-text">
                              You can rotate, zoom
                            </div>
                          </div>
                        ) : (
                          <img src={dImg} alt="" />
                        )}
                      </div> */}
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                          <div className="tp-p-details-img">
                            <img src={sImg1} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="tp-service-single-title">
                        <h3>{title}</h3>
                      </div>
                      <p>{des1}</p>
                      <p>{des2}</p>

                      <div className="tp-service-single-main-img">
                        {title === "Virtual Tours" ? (
                          <div className="panorama-container">
                            <ReactPhotoSphereViewer {...viewerOptions} />
                            <div className="overlay-text">
                              You can rotate, zoom
                            </div>
                          </div>
                        ) : (
                          <img src={dImg} alt="" />
                        )}
                      </div>
                      <p>{des3}</p>
                    </div>
                    <div className="tp-service-single-item list-widget">
                      <div className="tp-service-single-title">
                        <h3>Our Capabilities</h3>
                      </div>
                      <p>
                        Massa volutpat odio facilisis purus sit elementum. Non,
                        sed velit dictum quam. Id risus pharetra est, at
                        rhoncus, nec ullamcorper tincidunt. Id aliquet duis
                        sollicitudin diam.
                      </p>
                      <ul>
                        <li>Non saed velit dictum quam risus pharetra esta.</li>
                        <li>
                          Id risus pharetra est, at rhoncus, nec ullamcorper
                          tincidunt.
                        </li>
                        <li>
                          Hac nibh fermentum nisi, platea condimentum cursus.
                        </li>
                        <li>
                          Massa volutpat odio facilisis purus sit elementum.
                        </li>
                        <li>Elit curabitur amet risus bibendum.</li>
                      </ul>
                    </div>
                    {/* <div className="tp-service-single-item">
                      <div className="tp-service-single-title">
                        <h3>Our approach</h3>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Consequat suspendisse aenean tellus augue morbi risus.
                        Sit morbi vitae morbi sed urna sed purus. Orci facilisi
                        eros sed pellentesque. Risus id sed tortor sed
                        scelerisque. Vestibulum elit elementum, magna id viverra
                        non, velit. Pretium, eros, porttitor fusce auctor vitae
                        id. Phasellus scelerisque nibh eleifend vel enim mauris
                        purus. Rutrum vel sem adipiscing nisi vulputate molestie
                        scelerisque molestie ultrices. Eu, fusce vulputate diam
                        interdum morbi ac a.
                      </p>
                    </div>
                    <div className="tp-service-single-item list-widget">
                      <div className="tp-service-single-title">
                        <h3>Our Work Process</h3>
                      </div>
                      <ul>
                        <li>Non saed velit dictum quam risus pharetra esta.</li>
                        <li>
                          Id risus pharetra est, at rhoncus, nec ullamcorper
                          tincidunt.
                        </li>
                        <li>
                          Hac nibh fermentum nisi, platea condimentum cursus.
                        </li>
                        <li>
                          Massa volutpat odio facilisis purus sit elementum.
                        </li>
                      </ul>
                    </div>
                    <div className="tp-service-single-item">
                      <div className="tp-service-single-title">
                        <h3>Related Service</h3>
                      </div>
                      <div className="tp-service-area">
                        <div className="row align-items-center">
                          {Services.slice(0, 3).map((service, srv) => (
                            <div className="col-lg-4 col-md-6 col-12" key={srv}>
                              <div className="tp-service-item">
                                <i className={`fi ${service.icon}`}></i>
                                <h2>{service.sTitle}</h2>
                                <p>
                                  Lacus, etiam sed est eu tempus need Temer diam
                                  congue.
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>*/}
                    <div className="tp-service-single-item">
                      <div className="tp-service-contact-area">
                        <div className="tp-contact-title">
                          <h2>Have project in mind? Let's discuss</h2>
                          <p>
                            Get in touch with us to see how we can help you with
                            your project
                          </p>
                        </div>
                        <div className="tp-contact-form-area">
                          <Contact />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Dialog>
    </>
  );
}
