import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import Slide1 from "../../assets/images/slider/right-img.png";

export default function Portfolio() {
  return (
    <section className="tp-hero-section-1">
      <div className="container">
        <div className="row">
          <div className="col col-xs-7 col-lg-7">
            <div className="tp-hero-section-text">
              <div className="tp-hero-title">
                <h3>We build amazing website for your business.</h3>
              </div>
              <div className="tp-hero-sub">
                <p>
                  We do it for Corporates, Startups and NGO. We can do it for
                  you.
                </p>
              </div>
              <div className="btns">
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-95}
                  className="theme-btn"
                >
                  Our Portfolio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-vec">
        <div className="right-img">
          <img src={Slide1} alt="" />
        </div>
      </div>
      {/* <div className="social-link">
        <ul>
          <li>
            <NavLink to="/">Github</NavLink>
          </li>
          <li>
            <NavLink to="/">Facebook</NavLink>
          </li>
          <li>
            <NavLink to="/">Instagram</NavLink>
          </li>
        </ul>
      </div> */}
      <div className="visible-text">
        <h1>Portfolio</h1>
      </div>
    </section>
  );
}
