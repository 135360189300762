import React from "react";
import ReactDOM from "react-dom/client";
import App from "./main-component/App/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/style.scss";
// import './css/font-awesome.min.css';
import "./assets/css/themify-icons.css";
import "./assets/css/flaticon.css";
import "./assets/css/animate.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
